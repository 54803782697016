import { ChangeEvent, useEffect, useState } from 'react';
import { useUserContext } from '@/context/UserContext';
import { Customer } from '@/modules/apiTypes';

interface CustomerSelectionDropdownProps {
  customerSelectionLabel?: string;
}

const SELECTED_INDEX = 'selectedIndex';

export default function CustomerSelectionDropdown({
  customerSelectionLabel = 'Handla som:',
}: CustomerSelectionDropdownProps) {
  const { setSelectedCustomer, userInfo } = useUserContext();
  const [selectedIndex, setSelectedIndex] = useState<string>('');

  useEffect(() => {
    const storedIndex = localStorage.getItem(SELECTED_INDEX);
    if (storedIndex !== null) {
      setSelectedIndex(storedIndex);
    } else {
      setSelectedIndex('0');
      localStorage.setItem(SELECTED_INDEX, '0');
    }
  }, []);

  function handleOptionChange(event: ChangeEvent<HTMLSelectElement>) {
    const newIndex = event.target.value;
    setSelectedIndex(newIndex);
    localStorage.setItem(SELECTED_INDEX, newIndex);
    userInfo?.customers &&
      setSelectedCustomer(userInfo.customers[parseInt(newIndex)]);
  }

  const getCustomerLabel = (customer: Customer) =>
    customer.customer_type === 'company'
      ? customer.company_name
      : customer.first_name;

  if (selectedIndex === '') {
    return null;
  }

  return (
    <>
      {userInfo?.customers && (
        <div className="flex flex-wrap items-center justify-center">
          <p className="shrink-0">{customerSelectionLabel}</p>
          <select
            value={selectedIndex}
            onChange={handleOptionChange}
            className="mr-4 rounded-lg border-transparent bg-transparent py-1 text-xs font-bold md:text-base"
          >
            {userInfo.customers.map((customer, index) => (
              <option key={getCustomerLabel(customer)} value={index}>
                {getCustomerLabel(customer)}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}
