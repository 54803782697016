import Link from 'next/link';
import Image from 'next/image';
import CaretRight from '@/icons/caret-right.svg';
import { StrapiStoreStaticDataAttributes } from '@/modules/strapiTypes';
import { PathData } from './HLRStore';

interface BreadcrumbNavigationProps {
  data: StrapiStoreStaticDataAttributes;
  currentPath: PathData[];
}

export default function BreadcrumbNavigation({
  data,
  currentPath,
}: BreadcrumbNavigationProps) {
  return (
    <div className="flex h-min items-center text-xs md:text-base">
      <div className="flex shrink-0">
        <p
          className={`my-auto hover:underline md:mr-2 md:text-lg ${
            currentPath.length > 0 ? 'font-semibold' : 'text-gray-500'
          }`}
        >
          <Link href="/butik">
            {data?.breadcrumb_store.replaceAll(' ', '-') ?? 'Butik'}
          </Link>
        </p>
        {currentPath.length > 0 && (
          <Image
            className="md:mr-2"
            src={CaretRight as string}
            alt="CaretRight"
            height="24"
            width="24"
          />
        )}
      </div>
      {currentPath.map((path, index) => (
        <div className="flex md:space-x-2" key={path.id}>
          {index != 0 && (
            <Image
              className="md:ml-2"
              src={CaretRight as string}
              alt="CaretRight"
              height="24"
              width="24"
            />
          )}
          <Link
            className={`my-auto truncate hover:underline md:text-lg ${
              index != currentPath.length - 1
                ? 'font-semibold'
                : 'text-gray-500'
            }`}
            href={path.url}
          >
            {path.name}
          </Link>
        </div>
      ))}
    </div>
  );
}
