import Link from 'next/link';
import ProductCard from '@/components/general/ProductCard';
import CategoryCard from '@/components/pageStore/sectionHLRStore/partials/CategoryCard';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useMemo,
  ReactElement,
} from 'react';
import {
  getAllProductsInCategoryTree,
  productDescriptionToElement,
} from '@/utils/storeUtils';
import {
  Customer,
  WebshopCategory,
  WebshopFullCategory,
  WebshopProduct,
} from '@/modules/apiTypes';
import { getProducts } from '@/modules/productMapCache';
import { useUserContext } from '@/context/UserContext';
import { GtagItem, sendEcommerceEvent } from '@/modules/googleAnalytics';
import { WebshopCategoryMap } from '@/context/shoppingCartContext';
import ScrollCarousel from '@/components/general/ScrollCarousel';
import { categoryUrl } from '@/modules/helper';
import { StrapiStoreStaticDataAttributes } from '@/modules/strapiTypes';
import { StrapiRichText } from '../StrapiRichText';
import { usePathname } from 'next/navigation';

interface CategoryDetailProps {
  data?: StrapiStoreStaticDataAttributes;
  category?: WebshopFullCategory;
  topLevelCategories?: WebshopCategory[];
  displayedProducts: WebshopProduct[];
  preview?: boolean;
  isDetail: boolean;
  setCurrentProductParentCategory?: Dispatch<string>;
  categoryMap?: WebshopCategoryMap;
}

export default function CategoryDetail({
  data,
  category,
  topLevelCategories,
  displayedProducts: baseDisplayedProducts,
  preview = false,
  isDetail,
  setCurrentProductParentCategory,
  categoryMap,
}: CategoryDetailProps) {
  const { selectedCustomer, authToken } = useUserContext();

  const [displayedSubCategories, setDisplayedSubcategories] = useState<
    WebshopCategory[] | WebshopFullCategory[] | undefined
  >(isDetail ? category?.subcategories : topLevelCategories);
  useEffect(() => {
    category
      ? setDisplayedSubcategories(category.subcategories)
      : setDisplayedSubcategories(isDetail ? undefined : topLevelCategories);
  }, [category, isDetail, topLevelCategories]);

  const [displayedProducts, setDisplayedProducts] = useState<WebshopProduct[]>(
    baseDisplayedProducts
  );

  const [analyticsSent, setAnalyticsSent] = useState<boolean>(false);
  useEffect(() => {
    if (displayedProducts.length > 0 && !analyticsSent) {
      const gtagItemList: GtagItem[] = displayedProducts.map((product) => ({
        item_id: product.id.toString(),
        item_name: product.name,
        price: Number(product.custom_price_excl_vat ?? 0),
      }));
      sendEcommerceEvent({ name: 'view_item_list', items: gtagItemList });
      setAnalyticsSent(true);
    }
  }, [analyticsSent, displayedProducts]);

  useEffect(() => {
    category &&
      void getProductsInCategory(
        category,
        setDisplayedProducts,
        selectedCustomer,
        authToken
      );
  }, [authToken, category, selectedCustomer]);

  const [displayMoreDescription, setDisplayMoreDescription] =
    useState<boolean>(false);

  const categoryDescriptionContainer = useRef<HTMLDivElement>(null);

  const description = productDescriptionToElement(category?.description ?? '');

  // Manually set the max height of the description container to make the effect work in Safari
  useEffect(() => {
    const clampDescriptionContainer = () => {
      const divElement = categoryDescriptionContainer.current;
      if (divElement) {
        const lineHeight = parseInt(
          getComputedStyle(divElement).lineHeight,
          10
        );
        divElement.style.maxHeight = displayMoreDescription
          ? 'none'
          : `${lineHeight * 3}px`;
      }
    };
    !preview && clampDescriptionContainer();
  }, [displayMoreDescription, preview]);

  const groupedProducts = useMemo(() => {
    return groupAndSortProducts(
      getAllBottomCategoryIds(category),
      displayedProducts.filter(
        (product) => !product.categories.includes(category?.id ?? '')
      )
    );
  }, [category, displayedProducts]);

  return (
    <>
      {category ? (
        <h1 className="text-h2 md:mb-4">{category.name}</h1>
      ) : (
        <StrapiRichText
          additionalClassNames="text-h2 md:mb-4"
          richText={data?.store_header}
        />
      )}

      <div className="mb-4 md:mb-10">
        <div
          ref={categoryDescriptionContainer}
          className={`${
            displayMoreDescription || preview
              ? 'line-clamp-none'
              : 'line-clamp-4'
          }`}
        >
          {category ? (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            <div className="text-body-hero">
              <StrapiRichText richText={data?.store_description} />
            </div>
          )}
        </div>
        <button
          className="mt-2 font-bold hover:underline"
          onClick={() => setDisplayMoreDescription(!displayMoreDescription)}
        >
          {displayMoreDescription ? 'Läs mindre' : 'Läs mer'}
        </button>
      </div>
      <div className="mt-8">
        <StoreLeadooTag />
      </div>
      {displayedSubCategories && displayedSubCategories.length > 0 && (
        <>
          <h2 className="text-pre-header mb-6">
            {preview ? 'SORTIMENT' : data?.sub_categories_header}
          </h2>
          <div className="mb-10 grid grid-cols-2 gap-x-4 gap-y-10 md:mb-20 md:flex md:flex-wrap md:gap-x-8">
            {displayedSubCategories.map((category) => (
              <Link key={category.id} href={categoryUrl(category)}>
                <CategoryCard category={category} />
              </Link>
            ))}
          </div>
        </>
      )}
      {category?.products && category.products.length > 0 && (
        <div className="mb-10 md:mb-0">
          <h3 className="my-6 text-lg font-bold">{}</h3>
          <h2 className="text-pre-header mb-6">
            {data?.products_header ? data?.products_header : 'Produkter'}
          </h2>
          <div className="mb-10 grid grid-cols-2 gap-x-4 gap-y-10 md:mb-20 md:flex md:flex-wrap md:gap-x-8">
            {category.products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                productCategoryName={category.name}
                onClick={() => {
                  sendEcommerceEvent({
                    name: 'select_item',
                    items: [
                      {
                        item_id: product.id.toString(),
                        item_name: product.name,
                        price: Number(product.custom_price_excl_vat ?? 0),
                      },
                    ],
                  });

                  setCurrentProductParentCategory?.(category.id);
                }}
              />
            ))}
          </div>
        </div>
      )}
      {displayedProducts.length > 0 && (
        <div className="mb-10 md:mb-0">
          {groupedProducts.map(([categoryId, products]) => (
            <Fragment key={categoryId}>
              <h3 className="my-6 text-lg font-bold">{}</h3>
              <h2 className="text-pre-header">
                {categoryMap?.get(categoryId)?.name}
              </h2>
              <ScrollCarousel gap={16} pt={24} pb={16} sidePadding={false}>
                {products.map((product) => (
                  <ProductCard
                    key={product.id}
                    product={product}
                    productCategoryName={category?.name ?? ''}
                    onClick={() => {
                      sendEcommerceEvent({
                        name: 'select_item',
                        items: [
                          {
                            item_id: product.id.toString(),
                            item_name: product.name,
                            price: Number(product.custom_price_excl_vat ?? 0),
                          },
                        ],
                      });

                      setCurrentProductParentCategory?.(category?.id ?? '');
                    }}
                  />
                ))}
              </ScrollCarousel>
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
}

async function getProductsInCategory(
  fullCategory: WebshopFullCategory,
  setDisplayedProducts: Dispatch<SetStateAction<WebshopProduct[]>>,
  customer: Customer | undefined,
  authToken: string | undefined
): Promise<void> {
  function getProductIDs(category: WebshopFullCategory): string[] {
    const products = getAllProductsInCategoryTree(category);
    return products.map((product) => product.id.toString());
  }

  const prods = await getProducts(
    getProductIDs(fullCategory),
    customer,
    authToken
  );
  setDisplayedProducts(prods);
}

function groupAndSortProducts(
  bottomCategoryIds: string[],
  source: WebshopProduct[]
): [string, WebshopProduct[]][] {
  const groupedProductsMap = source.reduce((productMap, product) => {
    if (product.categories.length === 0) {
      return productMap;
    }
    product.categories.forEach((categoryId) => {
      if (!productMap.has(categoryId)) {
        productMap.set(categoryId, []);
      }

      const existingProducts = productMap.get(categoryId);
      const productExists = existingProducts?.some((p) => p.id === product.id);
      if (!productExists) {
        productMap.get(categoryId)?.push(product);
      }
    });

    return productMap;
  }, new Map<string, WebshopProduct[]>());

  const groupedProducts = Array.from(groupedProductsMap);

  const sortingMap: Record<string, number> = {};
  bottomCategoryIds.forEach((id, index) => {
    sortingMap[id] = index;
  });

  groupedProducts.sort(([c1], [c2]) => {
    const indexC1 = sortingMap[c1];
    const indexC2 = sortingMap[c2];
    return indexC1 - indexC2;
  });

  groupedProducts.forEach(([, products]) => {
    products.sort((p1, p2) => {
      if (
        p1.stock_status === 'not_in_stock' &&
        (p2.stock_status === 'in_stock' || p2.stock_status === 'low_in_stock')
      ) {
        return 1;
      }
      if (
        (p1.stock_status === 'in_stock' ||
          p1.stock_status === 'low_in_stock') &&
        p2.stock_status === 'not_in_stock'
      ) {
        return -1;
      }
      return 0;
    });
  });

  return groupedProducts;
}

function getAllBottomCategoryIds(
  category: WebshopFullCategory | undefined
): string[] {
  const categoriesWithoutSubcategories: string[] = [];

  function processCategory(category: WebshopFullCategory | undefined) {
    if (category) {
      if (category.products && category.products.length > 0) {
        categoriesWithoutSubcategories.push(category.id);
      }
      if (category.subcategories && category.subcategories.length > 0) {
        for (const subcategory of category.subcategories) {
          processCategory(subcategory);
        }
      }
    }
  }

  processCategory(category);
  return categoriesWithoutSubcategories.filter((id) => id !== category?.id);
}

function StoreLeadooTag(): ReactElement | null {
  const pathname = usePathname();
  if (pathname == '/butik') {
    return <ld-slot name="butik" />;
  } else if (pathname?.startsWith('/butik/kategori/1/')) {
    return <ld-slot name="kopa-hjartstartare" />;
  }

  return null;
}
