import React, { useEffect, useState } from 'react';
import ProductCard from '@/components/general/ProductCard';
import AmountBuyButton from '@/components/general/AmountBuyButton';
import { WebshopProduct } from '@/modules/apiTypes';
import Link from 'next/link';
import { sendEcommerceEvent } from '@/modules/googleAnalytics';
import { calculateTotalCost } from '@/utils/cartUtils';
import { useShoppingCart } from '@/context/shoppingCartContext';
import { productDescriptionToElement } from '@/utils/storeUtils';
import { getCategoryTree } from '@/utils/productUtils';
import { fbqTrackViewProduct } from '@/modules/metaPixel';
import Media from '@/components/general/Media';
import Image from 'next/image';
import PlayButton from '@/icons/play-button.svg';
import { productUrl } from '@/modules/helper';
import { ProductPrice } from '@/components/pageStore/ProductPrice';
import { StrapiStoreStaticDataAttributes } from '@/modules/strapiTypes';

interface ProductDetailProps {
  product: WebshopProduct;
  data?: StrapiStoreStaticDataAttributes;
  preview?: boolean;
}

export default function ProductDetail({
  product,
  data,
  preview = false,
}: ProductDetailProps) {
  const { displayVat, categoryMap } = useShoppingCart();
  const [selectedImage, setSelectedImage] = useState<
    (typeof product.images)[0] | undefined
  >(product.images[0]);

  const productIsBundle =
    product.bundled_products !== undefined &&
    product.bundled_products.length > 0;

  const description = productDescriptionToElement(product.description);

  const [analyticsSent, setAnalyticsSent] = useState<boolean>(false);
  useEffect(() => {
    if (!analyticsSent) {
      const categories = getCategoryTree(product, categoryMap);
      const { totalDiscountedCost } = calculateTotalCost(
        false,
        [{ amount: 1, product }],
        undefined
      );
      sendEcommerceEvent({
        name: 'view_item',
        value: totalDiscountedCost,
        currency: 'SEK',
        items: [
          {
            item_id: product.id.toString(),
            item_name: product.name,
            price: Number(product.custom_price_excl_vat ?? 0),
            item_category: categories.at(-1),
            item_category2: categories.at(-2),
            item_category3: categories.at(-3),
            item_category4: categories.at(-4),
            item_category5: categories.at(-5),
          },
        ],
      });

      fbqTrackViewProduct(product.id, totalDiscountedCost);

      setAnalyticsSent(true);
    }
  }, [analyticsSent, categoryMap, product]);

  const price = !!displayVat
    ? Number(product.price_incl_vat)
    : Number(product.price_excl_vat);
  const discountedPrice = !!displayVat
    ? Number(product.custom_price_incl_vat)
    : Number(product.custom_price_excl_vat);
  const discountPercentage = Math.trunc((1 - discountedPrice / price) * 100);

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-h2">{product.name}</h1>
        {!productIsBundle && (
          <div className="flex w-fit flex-wrap gap-1 text-sm font-light">
            <p className="w-fit">{`${
              data?.article_number_label ?? 'Artikelnummer:'
            } ${product.visma_id}${!!product.vendor_id ? ',' : ''}`}</p>
            {!!product.vendor_id && (
              <p>{`${
                data?.vendor_article_number_label ??
                'Tillverkarens artikelnummer:'
              } ${product.vendor_id}`}</p>
            )}
          </div>
        )}
        <div className="flex flex-col md:flex-row">
          <div className="flex max-w-md flex-col pt-4 md:w-1/2">
            <div className="relative mr-2 aspect-square w-full overflow-hidden rounded-lg md:mr-0">
              {product.top_badge && (
                <div className="absolute -left-1 top-0 z-10 w-36 -skew-x-20 bg-custom-red py-1">
                  <p className="ml-4 skew-x-12 text-base font-bold text-white">
                    {product.top_badge}
                  </p>
                </div>
              )}
              {product.show_discount_badge && discountPercentage > 0 && (
                <div className="absolute right-2 top-2 z-10 flex h-16 w-16 items-center justify-center rounded-full bg-custom-red">
                  <p className="text-2xl font-bold italic text-white">
                    {`-${discountPercentage}%`}
                  </p>
                </div>
              )}
              {selectedImage && (
                <Media
                  media={selectedImage}
                  imageAdditionalClasses="w-full rounded-lg border object-contain"
                  videoAdditionalClasses="h-full w-full rounded-lg border object-contain"
                  muted={true}
                  controls={true}
                  sizes="(max-width: 768px) 100vw, 25vw"
                />
              )}
              {product.bottom_badge && (
                <div className="absolute -right-1 bottom-0 z-10 w-36 -skew-x-20 bg-hlr-gunmetal py-1">
                  <p className="mr-4 skew-x-12 text-end text-base font-bold text-white">
                    {product.bottom_badge}
                  </p>
                </div>
              )}
            </div>
            <div className="mt-2 grid w-full grid-cols-4 gap-2">
              {product.images.map((media) => (
                <div
                  className="relative h-20 cursor-pointer"
                  key={media.url}
                  onClick={() => setSelectedImage(media)}
                >
                  <Media
                    media={media}
                    imageAdditionalClasses="aspect-square rounded-lg border object-cover"
                    videoAdditionalClasses="aspect-square h-full w-full rounded-lg border object-cover"
                    sizes="(max-width: 768px) 25vw, 10vw"
                  />
                  {media.mediaType === 'video' && (
                    <Image
                      className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                      src={PlayButton as string}
                      alt="play-button"
                      height={60}
                      width={60}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col overflow-x-clip pt-4 md:w-1/2 md:px-8">
            {productIsBundle && <BundleDetails {...product} />}
            <div className="mb-4 flex items-center gap-4">
              <div className="mr-auto whitespace-nowrap font-mulish text-22px font-bold md:text-32px">
                <ProductPrice displayVat={displayVat} product={product} />
              </div>
              <AmountBuyButton sizeVariant={'big'} product={product} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      </div>
      {product.related_products && product.related_products.length > 0 && (
        <div className="mt-10">
          <div className="text-pre-header mb-6">
            {preview ? 'RELATERADE PRODUKTER' : data?.related_products_label}
          </div>
          <div className="flex flex-wrap gap-x-4 gap-y-10 pb-10 md:gap-x-8">
            {product.related_products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                productCategoryName={''}
              />
            ))}
          </div>
        </div>
      )}
      {product.accessories && product.accessories.length > 0 && (
        <div className="mt-10">
          <div className="text-pre-header mb-6">
            {preview ? 'TILLBEHÖR' : data?.accessories_label}
          </div>
          <div className="flex flex-wrap gap-x-4 gap-y-10 md:gap-x-8">
            {product.accessories.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                productCategoryName={''}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

function BundleDetails(product: WebshopProduct) {
  return (
    <div className="mb-4">
      {product.bundled_products?.map((product) => {
        const productMedia = product.images.at(0);
        return (
          <div
            key={product.id}
            className="my-3 flex w-full flex-row justify-between pt-3 md:my-2 md:pt-0"
          >
            <Link className="group flex flex-row" href={productUrl(product)}>
              {productMedia && (
                <div className="relative h-16 w-16 shrink-0 transition-opacity group-hover:opacity-70 md:w-20">
                  <Media
                    media={productMedia}
                    imageAdditionalClasses="rounded border object-cover"
                    videoAdditionalClasses="h-full w-full rounded object-cover"
                    sizes="(max-width: 768px) 10vw, 10vw"
                  />
                </div>
              )}
              <div className="h-min px-6 transition-opacity group-hover:opacity-70 hover:underline md:py-2">
                <p className="font-raleway font-semibold md:text-lg">
                  {product.name}
                </p>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
}
