'use client';
import { useUserContext } from '@/context/UserContext';
import CustomerSelectionDropdown from '@/components/sectionNavbar/partials/CustomerSelectionDropdown';
import { useState } from 'react';
import { ModalVisibility, NavbarModal } from './NavbarModal/NavbarModal';
import PriceConfigurationDropDown from './PriceConfigurationDropDown';
import { StrapiPriceConfiguration } from '@/modules/strapiTypes';

export function PriceConfigurationBar(data: StrapiPriceConfiguration) {
  const { selectedCustomer, ...userContextUtils } = useUserContext();

  const [userModalVisibility, setUserModalVisibility] = useState(
    ModalVisibility.None
  );

  const texts = data?.attributes;

  const handleUserIconClick = () => {
    setUserModalVisibility(ModalVisibility.Login);
  };

  return (
    <div className="h-fit w-full border-b-2 bg-custom-lightgrey md:h-12">
      <div className="mx-auto flex h-fit max-w-page-width-mx-80 flex-col items-center justify-evenly gap-1 px-default-sm py-1 text-xs md:h-12 md:flex-row md:justify-between md:px-default md:py-0 md:text-base">
        {!selectedCustomer ? (
          <div className="flex gap-1">
            <p>{texts?.login_text}</p>
            <button
              className="font-bold hover:underline"
              onClick={handleUserIconClick}
            >
              {texts?.login_link}
            </button>
          </div>
        ) : (
          <div className="inline-flex">
            <p>{texts?.welcome_text}!&nbsp;</p>
            <button
              className="cursor-pointer font-bold hover:underline"
              onClick={() => userContextUtils.logOut()}
            >
              {texts?.logout_text.charAt(0).toUpperCase()}
              {texts?.logout_text.slice(1)}
            </button>
          </div>
        )}
        <div className="flex flex-row">
          {selectedCustomer && (
            <CustomerSelectionDropdown
              customerSelectionLabel={texts?.customer_option_text}
            />
          )}
          <PriceConfigurationDropDown
            showPriceText={texts?.price_option_text}
            priceIncludingVatText={texts?.price_including_vat_text}
            priceExcludingVatText={texts?.price_excluding_vat_text}
          />
        </div>
        <NavbarModal
          visibility={userModalVisibility}
          setVisibility={setUserModalVisibility}
        />
      </div>
    </div>
  );
}
