import Image from 'next/image';
import Link from 'next/link';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CaretDown from '@/icons/caret-down.svg';
import CaretRight from '@/icons/caret-right.svg';
import { getCategoryPath } from '@/utils/storeUtils';
import { WebshopCategoryMap } from '@/context/shoppingCartContext';
import { WebshopCategory, WebshopProduct } from '@/modules/apiTypes';
import { getProducts } from '@/modules/productMapCache';
import { categoryUrl, productUrl } from '@/modules/helper';
import { PathData } from '@/strapiComponents/HLRStore/HLRStore';

interface CategoryNavigationProps {
  category: WebshopCategory;
  categoryMap: WebshopCategoryMap;
  currentPath: PathData[];
  expandedPath: PathData[];
  setExpandedPath: Dispatch<SetStateAction<PathData[]>>;
  setCurrentProductParentCategory?: Dispatch<string>;
  baseUrl?: string;
}

export default function CategoryNavigation({
  category,
  categoryMap,
  currentPath,
  expandedPath,
  setExpandedPath,
  setCurrentProductParentCategory,
  baseUrl,
}: CategoryNavigationProps) {
  const categoryInExpandedPath = isInPath(category.id, expandedPath, false);
  const categoryInCurrentPath = isInPath(category.id, currentPath, false);
  const [products, setProducts] = useState<WebshopProduct[]>([]);
  const subcategories = category.subcategories
    ?.map((c) => categoryMap.get(c.toString()))
    .filter((c) => c) as WebshopCategory[];

  useEffect(() => {
    async function getCategoryProducts() {
      const products = await getProducts(
        category.products?.map((p) => p.toString()) ?? [],
        undefined,
        undefined
      );
      setProducts(products);
    }
    categoryInExpandedPath && void getCategoryProducts();
  }, [categoryInExpandedPath, category]);

  const handleCaretClick = () => {
    categoryInExpandedPath
      ? setExpandedPath(getCategoryPath(category.id, categoryMap).slice(0, -1))
      : setExpandedPath(getCategoryPath(category.id, categoryMap));
  };

  return (
    <div className={`${!category.parent_category ? 'mb-2' : 'mb-1'}`}>
      <div className="flex items-center">
        <Image
          src={(categoryInExpandedPath ? CaretDown : CaretRight) as string}
          alt="CaretDownRight"
          height="18"
          width="18"
          className="mr-1 cursor-pointer"
          onClick={handleCaretClick}
        />
        <p
          className={`hover:underline ${
            !category.parent_category ? 'text-base' : 'text-sm'
          }  ${categoryInCurrentPath ? 'font-bold' : 'font-normal'} ${
            categoryInCurrentPath && !category.parent_category
              ? 'underline'
              : 'no-underline'
          }`}
        >
          <Link href={categoryNavUrl(category, baseUrl)}>
            {category.menu_name || category.name}
          </Link>
        </p>
      </div>
      {categoryInExpandedPath && (
        <div className="my-1 ml-5 md:my-2">
          {subcategories.map((subcategory) => (
            <CategoryNavigation
              key={subcategory.id}
              category={subcategory}
              categoryMap={categoryMap}
              currentPath={currentPath}
              expandedPath={expandedPath}
              setExpandedPath={setExpandedPath}
              setCurrentProductParentCategory={setCurrentProductParentCategory}
              baseUrl={baseUrl}
            />
          ))}
        </div>
      )}
      {categoryInExpandedPath && (
        <div className="mb-2 ml-6 md:mb-3">
          {products.map((product) => (
            <p
              key={product.id}
              className={`text-xs hover:underline md:mb-1 md:text-sm ${
                isInPath(product.id.toString(), currentPath, true)
                  ? 'font-bold'
                  : 'font-normal'
              }`}
            >
              <Link
                href={productNavUrl(product, baseUrl)}
                onClick={() => setCurrentProductParentCategory?.(category.id)}
              >
                {product.menu_name || product.name}
              </Link>
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

function isInPath(id: string, pathData: PathData[], product: boolean) {
  return pathData.some((path) => path.id === id && path.isProduct === product);
}

function categoryNavUrl(category: WebshopCategory, basePath?: string): string {
  if (!!basePath) {
    return categoryUrl(category).replace('/butik', basePath);
  }
  return categoryUrl(category);
}

function productNavUrl(product: WebshopProduct, basePath?: string): string {
  if (!!basePath) {
    return productUrl(product).replace('/butik', basePath);
  }
  return productUrl(product);
}
