import Media from '@/components/general/Media';
import { WebshopCategory, WebshopFullCategory } from '@/modules/apiTypes';
import Image from 'next/image';

interface CategoryCardProps {
  category: WebshopCategory | WebshopFullCategory;
}

export default function CategoryCard({ category }: CategoryCardProps) {
  const backupUrl = '/images/logo.png';

  return (
    <div className="mx-auto w-32 transition-opacity hover:opacity-60 md:w-56">
      <div className="relative h-24 w-32 rounded-lg bg-white brightness-95 md:h-40 md:w-56 md:rounded-xl">
        {category.image ? (
          <Media
            media={category.image}
            imageAdditionalClasses="rounded-lg object-cover p-2 md:rounded-xl"
            videoAdditionalClasses="rounded-lg object-cover p-2 md:rounded-xl w-full h-full"
            sizes="(max-width: 768px) 50vw, 25vw"
          />
        ) : (
          <Image
            src={backupUrl}
            alt="backupImage"
            className="rounded-lg object-cover p-2 md:rounded-xl"
            fill
            sizes="(max-width: 768px) 50vw, 25vw"
          />
        )}
      </div>
      <p className="mt-3 text-sm font-bold hover:underline md:text-lg">
        {category.name}
      </p>
    </div>
  );
}
