'use client';
import React, { useEffect, useMemo, useState } from 'react';
import CategoryNavigation from '@/components/general/CategoryNavigation';
import CaretDown from '@/icons/caret-down.svg';
import CaretRight from '@/icons/caret-right.svg';
import {
  getCategoryPath,
  getProductPath,
  useCategoriesWithFallback,
} from '@/utils/storeUtils';
import Image from 'next/image';
import { mediumWidth, useWindowWidth } from '@/utils/generalUtils';
import { useUserContext } from '@/context/UserContext';
import { useShoppingCart } from '@/context/shoppingCartContext';
import {
  WebshopCategory,
  WebshopFullCategory,
  WebshopProduct,
} from '@/modules/apiTypes';
import { getCategoryRequest, getProductRequest } from '@/modules/apiClient';
import { StrapiStoreStaticDataAttributes } from '@/modules/strapiTypes';
import BreadcrumbNavigation from './BreadcrumbNavigation';
import CategoryDetail from './CategoryDetail';
import ProductDetail from './ProductDetail';

interface StoreProps {
  data: StrapiStoreStaticDataAttributes;
  categories: WebshopCategory[];
  selectedCategory?: WebshopFullCategory;
  selectedProduct?: WebshopProduct;
  categoryProducts?: WebshopProduct[];
  isDetail: boolean;
  isProductPage?: boolean;
  pid?: string;
}

export interface PathData {
  id: string;
  name: string;
  url: string;
  isProduct: boolean;
}

export default function HLRStore({
  data,
  categories,
  selectedProduct: baseSelectedProduct,
  selectedCategory,
  categoryProducts,
  isDetail,
  isProductPage,
  pid,
}: StoreProps) {
  const categoryMap = useCategoriesWithFallback(categories);
  const topLevelCategories = useMemo(
    () => categories.filter((c) => !c.parent_category),
    [categories]
  );

  // Show categories by default if the window width is large enough, otherwise hide them
  const windowWidth = useWindowWidth();
  const isDesktopView = useMemo(() => windowWidth > mediumWidth, [windowWidth]);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [expandedPath, setExpandedPath] = useState<PathData[]>([]);
  const [currentPath, setCurrentPath] = useState<PathData[]>([]);
  const [currentProductParentCategory, setCurrentProductParentCategory] =
    useState<string>('');
  const { selectedCustomer, authToken } = useUserContext();
  const { setDisplayVat } = useShoppingCart();

  useEffect(() => {
    if (selectedCustomer) {
      if (selectedCustomer.customer_type === 'private') {
        setDisplayVat(true);
      } else {
        setDisplayVat(false);
      }
    }
  }, [selectedCustomer, setDisplayVat]);

  const [selectedProduct, setSelectedProduct] = useState<
    WebshopProduct | undefined
  >(baseSelectedProduct);
  const [category, setCategory] = useState<WebshopFullCategory | undefined>(
    undefined
  );

  useEffect(() => {
    function setPaths() {
      if (isProductPage) {
        const productPath = baseSelectedProduct
          ? getProductPath(
              baseSelectedProduct,
              categoryMap,
              currentProductParentCategory
            )
          : [];
        setExpandedPath(productPath);
        setCurrentPath(productPath);
      } else {
        const detail = categoryMap.get(pid ?? '');
        if (detail) {
          const categoryPath = getCategoryPath(detail.id, categoryMap);
          setExpandedPath(categoryPath);
          setCurrentPath(categoryPath);
        }
      }
    }
    setPaths();
  }, [
    baseSelectedProduct,
    categoryMap,
    currentProductParentCategory,
    isProductPage,
    pid,
  ]);

  useEffect(() => {
    let isInvalidated = false;
    async function fetch() {
      if (isProductPage) {
        const product = await getProductRequest(
          pid ?? '',
          selectedCustomer,
          authToken
        );
        if (isInvalidated) {
          return;
        }
        setSelectedProduct(product);
      } else {
        if (isInvalidated) {
          return;
        }
        setSelectedProduct(undefined);
      }
    }
    void fetch();
    return () => {
      isInvalidated = true;
    };
  }, [authToken, isProductPage, pid, selectedCustomer]);

  useEffect(() => {
    let isInvalidated = false;
    const fetchData = async () => {
      if (!selectedCategory || !selectedCustomer || !authToken) {
        return;
      }

      const res = await getCategoryRequest(
        selectedCategory.id,
        selectedCustomer,
        authToken
      );
      if (isInvalidated) {
        return;
      }
      setCategory(res);
    };

    if (selectedCategory) {
      void fetchData();
      return () => {
        isInvalidated = true;
      };
    }
  }, [authToken, selectedCategory, selectedCustomer]);

  return (
    <>
      {categories.length > 0 && (
        <div className="mx-auto mt-8 grid max-w-page-width-mx-80 grid-cols-1 px-default-sm md:mt-12 md:grid-cols-[240px_minmax(0,_1fr)] md:gap-12 md:px-default">
          <div>
            <div
              onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
              className="flex cursor-pointer justify-between border-b border-custom-grey py-4 md:cursor-default md:border-0 md:py-0"
            >
              <h3 className="my-auto">
                {data?.category_navigation_header ?? 'Kategorier'}
              </h3>
              <Image
                src={(showCategoryDropdown ? CaretDown : CaretRight) as string}
                alt="CaretDownRight"
                height="18"
                width="18"
                className="mr-1 cursor-pointer md:hidden"
              />
            </div>
            <div className="md:custom-divider-red md:my-6" />
            {(showCategoryDropdown || isDesktopView) && (
              <div className="pt-4 md:mb-20 md:pt-0">
                {topLevelCategories.map((category) => (
                  <CategoryNavigation
                    key={category.id}
                    category={category}
                    categoryMap={categoryMap}
                    currentPath={currentPath}
                    expandedPath={expandedPath}
                    setExpandedPath={setExpandedPath}
                    setCurrentProductParentCategory={
                      setCurrentProductParentCategory
                    }
                  />
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-1 flex-col">
            <div className="mt-4 overflow-x-scroll md:mt-0 md:overflow-hidden">
              <BreadcrumbNavigation data={data} currentPath={currentPath} />
            </div>
            <div className="mb-8 mt-2 md:mb-20 md:mt-4">
              {!selectedProduct ? (
                <CategoryDetail
                  data={data}
                  category={category ?? selectedCategory}
                  topLevelCategories={topLevelCategories}
                  displayedProducts={categoryProducts ?? []}
                  isDetail={isDetail}
                  setCurrentProductParentCategory={
                    setCurrentProductParentCategory
                  }
                  categoryMap={categoryMap}
                />
              ) : (
                <ProductDetail
                  product={selectedProduct}
                  data={data}
                  key={selectedProduct.id}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
